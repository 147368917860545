<template>
  <div v-if="loading" class="clearfix">
    <b-spinner class="float-right" label="Floated Right" />
  </div>
  <div v-else>
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Atendimentos </span>
        </template>
        <app-collapse type="margin">
          <app-collapse-item title="Atendimentos" :isVisible="true">
            <div class="justify-content-end d-flex flex-wrap">
              <b-button
                v-if="parseInt(form.situacao.id) === 10 && disableForm"
                class="mr-1"
                variant="primary"
                @click="atestado"
                >Atestado</b-button
              >

              <b-button
                v-if="parseInt(form.situacao.id) === 10 && disableForm"
                class="mr-1"
                variant="primary"
                @click="declaracao"
                >Declaração</b-button
              >

              <b-button
                v-if="parseInt(form.situacao.id) === 10 && disableForm"
                class="mr-1"
                variant="primary"
                @click="exame"
                >Solicitação de Exames</b-button
              >

              <b-button
                v-if="disableForm"
                variant="outline-success"
                @click="sair"
                >Sair</b-button
              >
            </div>
            <validation-observer ref="form">
              <b-form @submit.prevent>
                <b-row>
                  <b-col cols="12" lg="4">
                    <b-form-group
                      label="Número Atendimento"
                      label-for="v-numAtendimento"
                    >
                      <b-form-input
                        id="v-numAtendimento"
                        v-model="form.numeroAtendimento"
                        placeholder="numAtendimento"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <b-form-group
                      label="Data Atendimento"
                      label-for="v-dataAtendimento"
                    >
                      <flat-pickr
                        v-model="form.dataAtendimento"
                        name="fieldDateData"
                        class="form-control"
                        :disabled="true"
                        :config="{
                          altInput: true,
                          altFormat: 'd/m/Y',
                          enableTime: false,
                          dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <b-form-group
                      label="Data Cancelamento"
                      label-for="v-dataCancelamento"
                    >
                      <b-form-input
                        id="v-dataCancelamento"
                        v-model="form.dataCancelamento"
                        placeholder="dataCancelamento"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" lg="4">
                    <b-form-group
                      label="Tipo de Atendimento"
                      label-for="v-tipoAtendimento"
                    >
                      <b-form-input
                        id="v-tipoAtendimento"
                        v-model="form.tipoAtendimento.descricao"
                        placeholder="tipoAtendimento"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <b-form-group
                      label="Especialidades"
                      label-for="v-especialidade"
                    >
                      <b-form-input
                        id="v-especialidade"
                        v-model="form.especialidade.descricao"
                        placeholder="especialidade"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <b-form-group
                      label="Profissional"
                      label-for="v-profissional"
                    >
                      <b-form-input
                        id="v-profissional"
                        v-model="form.profissional.nome"
                        placeholder="profissional"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Solicitante" label-for="v-registro">
                      <b-form-input
                        id="v-registro"
                        v-model="form.registro.nome"
                        placeholder="registro"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" lg="4">
                    <validation-provider
                      #default="{ errors }"
                      name="Hora Inicial"
                      rules="required"
                    >
                      <b-form-group
                        label="Hora Inicial"
                        label-for="v-horaInicial"
                      >
                        <flat-pickr
                          v-model="form.horaInicial"
                          name="fieldDate"
                          :disabled="disableForm"
                          :config="{
                            altInput: true,
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                            time_24hr: true
                          }"
                        />
                        <small
                          data-cy="v-horaInicial-validate"
                          class="text-danger"
                          >{{ errors[0] }}</small
                        >
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <validation-provider
                      #default="{ errors }"
                      name="Hora Final"
                      rules="required"
                    >
                      <b-form-group label="Hora Final" label-for="v-horaFinal">
                        <flat-pickr
                          v-model="form.horaFinal"
                          name="fieldDate"
                          :disabled="disableForm"
                          :config="{
                            altInput: true,
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                            time_24hr: true
                          }"
                        />
                        <small
                          data-cy="v-horaFinal-validate"
                          class="text-danger"
                          >{{ errors[0] }}</small
                        >
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" sm="12" lg="4">
                    <b-form-group label="CID" label-for="v-doencasCid">
                      <v-select
                        v-model="form.doencasCid"
                        :reduce="(comboDoencasCid) => comboDoencasCid.id"
                        label="nome"
                        :options="comboDoencasCid"
                        multiple
                        :readonly="disableForm"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" sm="12" md="4" lg="4">
                    <b-form-group
                      label="Unidade Atendimento"
                      label-for="v-unidadeAtendimento"
                    >
                      <b-form-input
                        id="v-unidadeAtendimento"
                        v-model="form.unidadeAtendimento.descricao"
                        placeholder="unidadeAtendimento"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="12" md="4" lg="4">
                    <b-form-group label="Situação" label-for="v-situacao">
                      <v-select
                        v-model="form.situacao.id"
                        :reduce="(comboSituacao) => comboSituacao.id"
                        label="nome"
                        :options="comboSituacao"
                        :disabled="disableForm"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="12" md="4" lg="4">
                    <b-form-group
                      label="Dias para Atestado"
                      label-for="v-atestado"
                    >
                      <b-input-group>
                        <b-form-input
                          id="v-atestado"
                          v-model="form.diasAtestado"
                          placeholder="dias atestado"
                          type="number"
                          :disabled="disableForm"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" sm="12" md="12" lg="12">
                    <b-form-group label="Observações" label-for="v-observacao">
                      <b-form-textarea
                        id="v-observacao"
                        v-model="form.observacao"
                        row="2"
                        placeholder="Digite aqui suas observações"
                        :disabled="disableForm"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
            <ButtonsFormVue
              :disable-form="disableForm"
              :loading-button="loading"
              :permission-insert="verificarPermissao('ROLE_PROFISSIONAL')"
              :permission-edit="verificarPermissao('ROLE_PROFISSIONAL')"
              @save="salvar"
              @edit="editar"
              @cancel="cancelar"
            />
          </app-collapse-item>
        </app-collapse>
      </b-tab>
      <b-tab v-for="item in listaGrupos" :key="item.id">
        <template #title>
          <feather-icon icon="CheckIcon" />
          <span>{{ item.grupo }}</span>
          &nbsp;
          <b-spinner
            v-if="loadingCampos"
            class="float-right"
            label="Floated Right"
            small
          />
        </template>
        <app-collapse
          v-for="procedimento in item.procedimentos"
          :key="procedimento.id"
          type="margin"
        >
          <app-collapse-item :isVisible="true" :title="procedimento.nome">
            <b-row
              v-if="
                item.procedimentos.length === 0 ||
                procedimento.campo_adicional.length === 0
              "
            >
              <b-col> Nenhum campo configurado </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                sm="6"
                v-for="campo in procedimento.campo_adicional"
                :key="campo.id"
              >
                <b-form-group
                  v-if="campo.tipo === TYPES_FIELDS.STRING"
                  :label="campo.nome"
                >
                  <b-form-input
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    type="text"
                    :disabled="disableForm"
                  />
                </b-form-group>

                <b-form-group
                  v-if="campo.tipo === TYPES_FIELDS.TEXTO"
                  :label="campo.nome"
                >
                  <b-form-textarea
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    :placeholder="campo.descricao"
                    rows="3"
                    :disabled="disableForm"
                  />
                </b-form-group>

                <b-form-group
                  v-if="
                    campo.tipo === TYPES_FIELDS.INTEGER ||
                    campo.tipo === TYPES_FIELDS.DECIMAL
                  "
                  :label="campo.nome"
                >
                  <b-form-input
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    type="number"
                    :disabled="disableForm"
                  />
                </b-form-group>

                <b-form-group
                  v-if="campo.tipo === TYPES_FIELDS.DATA"
                  :label="campo.nome"
                >
                  <flat-pickr
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    name="fieldDate"
                    class="form-control"
                    :config="{
                      altInput: true,
                      altFormat: 'd/m/Y',
                      enableTime: false,
                      dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                    }"
                    :readonly="disableForm"
                    :disabled="disableForm"
                  />
                </b-form-group>

                <b-form-group
                  v-if="campo.tipo === TYPES_FIELDS.BOOLEAN"
                  :label="campo.nome"
                >
                  <b-form-checkbox
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    name="check-button"
                    switch
                    inline
                    :value="'true'"
                    :unchecked-value="'false'"
                    :disabled="disableForm"
                  >
                    {{ !campo.valor ? 'Não' : 'Sim' }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
        <ButtonsFormVue
          :disable-form="disableForm"
          :loading-button="loadingCampos"
          :permission-insert="verificarPermissao('ROLE_PROFISSIONAL')"
          :permission-edit="verificarPermissao('ROLE_PROFISSIONAL')"
          @save="salvarCamposAdicionais"
          @edit="editar"
          @cancel="cancelar"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  BRow,
  BCol,
  BSpinner,
  BFormGroup,
  BFormInput,
  BTabs,
  BTab,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import flatPickr from 'vue-flatpickr-component'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'
import mixinsGlobal from '@/mixins'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BSpinner,
    BFormGroup,
    BFormInput,
    BTabs,
    BTab,
    flatPickr,
    ButtonsFormVue,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BButton,
    BInputGroup
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('atendimentoProfissionalModule', {
      atendimento: (state) => state.atendimento
    }),
    ...mapState('combosModule', {
      comboDoencasCid: (state) => state.comboDoencasCid
    })
  },
  data() {
    return {
      required,
      collapseType: 'default',
      listaGrupos: [],
      loading: false,
      loadingCampos: false,
      disableForm: true,
      form: {
        id: null,
        numeroAtendimento: '',
        dataAtendimento: '',
        observacao: '',
        situacao: {
          id: null,
          descricao: ''
        },
        dataCancelamento: null,
        profissional: {
          id: null,
          nome: '',
          especialidades: []
        },
        especialidade: {
          id: null,
          descricao: null
        },
        registro: {
          id: null,
          nome: ''
        },
        unidadeAtendimento: {
          id: null,
          descricao: null
        },
        agendamento: null,
        convenio: {
          id: null,
          descricao: null
        },
        tipoAtendimento: {
          id: null,
          descricao: ''
        },
        horaInicial: '',
        horaAgenda: null,
        horaFinal: null,
        campoAtendimento: [],
        hora: '',
        diasAtestado: 0,
        doencasCid: null
      },
      comboSituacao: [],
      report: {},
      TYPES_FIELDS: {
        STRING: 0,
        BOOLEAN: 1,
        DECIMAL: 2,
        INTEGER: 3,
        DATA: 4,
        TEXTO: 5
      }
    }
  },
  methods: {
    async editar() {
      this.disableForm = false
      this.aplicarEstiloDisabilitadoEmCampoData()
    },
    cancelar() {
      this.disableForm = true
      this.loadingCampos = false
      this.setObejetoLocalEdicao()
      this.aplicarEstiloDisabilitadoEmCampoData()
      const dateName = document.getElementsByName('fieldDateData')
      if (dateName.length > 0) {
        dateName.forEach((element) => {
          if (element.nextElementSibling) {
            element.nextElementSibling.style.backgroundColor = ''
            if (this.disableForm) {
              element.nextElementSibling.style.backgroundColor = '#efefef'
            }
          }
        })
      }
      this.$refs.form.reset()
    },
    setObejetoLocalEdicao() {
      this.form = {
        id: this.atendimento.id,
        numeroAtendimento: this.atendimento.numeroAtendimento,
        dataAtendimento: this.atendimento.dataAtendimento,
        observacao: this.atendimento.observacao,
        situacao: this.atendimento.situacao,
        dataCancelamento: this.atendimento.dataCancelamento,
        profissional: this.atendimento.profissional,
        especialidade: this.atendimento.especialidade,
        registro: this.atendimento.registro,
        unidadeAtendimento: this.atendimento.unidadeAtendimento,
        agendamento: this.atendimento.agendamento,
        convenio: this.atendimento.convenio,
        tipoAtendimento: this.atendimento.tipoAtendimento,
        horaInicial: this.atendimento.horaInicial,
        horaAgenda: this.atendimento.horaAgenda,
        horaFinal: this.atendimento.horaFinal,
        campoAtendimento: this.atendimento.campoAtendimento,
        hora: this.atendimento.horaAgenda,
        diasAtestado: this.atendimento.diasAtestado,
        doencasCid: this.atendimento.doencasCid
      }
    },
    async loadComboSituacao() {
      this.$store
        .dispatch('atendimentoProfissionalModule/getComboSituacao')
        .then((response) => {
          this.comboSituacao = response.data.map((item) => ({
            id: item.id,
            nome: item.descricao
          }))
        })
    },
    async loadComboDoencasCid() {
      await this.$store.dispatch('combosModule/loadComboDoencasCid')
    },
    salvar() {
      this.$refs.form.validate().then((success) => {
        const objectSave = {
          id: this.form.id,
          observacao: this.form.observacao,
          situacao: this.form.situacao.id,
          horaInicial: this.form.horaInicial,
          horaFinal: this.form.horaFinal,
          diasAtestado: this.form.diasAtestado,
          doencasCid: this.form.doencasCid
        }
        if (success) {
          this.loading = true
          try {
            this.$store
              .dispatch('atendimentoProfissionalModule/edit', objectSave)
              .then((response) => {
                if (
                  response.response &&
                  response.response.data &&
                  response.response.data.error
                ) {
                  this.MensagemError('Editar', 'Falha ao editar!')
                  return
                }
                this.showMessageSuccess('Editar', 'Editado com sucesso!')
                this.loadDadosForm()
                this.loading = false
              })
            return
          } catch (error) {
            this.MensagemError('Editar', 'Falha ao editar!')
            this.loading = false
          }
        }
      })
    },
    loadDadosForm() {
      this.loading = true
      this.$store
        .dispatch(
          'atendimentoProfissionalModule/getAtendimentoById',
          this.$route.params.id
        )
        .then(() => {
          this.cancelar()
        })
        .finally(() => {
          this.loading = false
        })
    },
    getGrupos() {
      this.loading = true
      this.montarAbas()
      this.loading = false
    },
    sair() {
      this.$router.go(-1)
    },
    exame() {
      this.report = {}
      this.report = {
        nome: 'solicitacao_exame',
        valor: [parseInt(this.form.id)]
      }
      this.$store
        .dispatch('reportModule/printReport', this.report)
        .then((response) => {
          if (response.error) {
            this.showMessageSuccess('Limite Excedido', response.message)
          }
          this.openNewAbaBase64(response.data)
        })
    },
    atestado() {
      this.report = {}
      this.report = {
        nome: 'atestado_atendimento',
        valor: [parseInt(this.form.id)]
      }
      this.$store
        .dispatch('reportModule/printReport', this.report)
        .then((response) => {
          if (response.error) {
            this.showMessageSuccess('Limite Excedido', response.message)
          }
          this.openNewAbaBase64(response.data)
        })
    },
    declaracao() {
      this.report = {}
      this.report = {
        nome: 'declaracao_atendimento',
        valor: [parseInt(this.form.id)]
      }
      this.$store
        .dispatch('reportModule/printReport', this.report)
        .then((response) => {
          if (response.error) {
            this.showMessageSuccess('Limite Excedido', response.message)
          }
          this.openNewAbaBase64(response.data)
        })
    },
    montarAbas() {
      this.$store
        .dispatch(
          'atendimentoProfissionalModule/montarTela',
          this.$route.params.id
        )
        .then((response) => {
          this.loadListaGrupos(response.data)
        })
    },
    loadListaGrupos(atendimento) {
      this.listaGrupos = []
      atendimento.atendimento.grupo_procedimento.forEach((grupo) => {
        if (grupo.id !== 0) {
          this.listaGrupos.push({
            id: grupo.id,
            grupo: grupo.nome ? grupo.nome : 'Padrão',
            procedimentos: grupo.procedimento
          })
        }
      })
      this.listaGrupos.forEach((item) => {
        item.procedimentos.forEach((procedimento) => {
          procedimento.campo_adicional.forEach((campo) => {
            campo.valor =
              campo.campo_atendimento.length > 0
                ? campo.campo_atendimento[0]?.valor
                : null
          })
        })
      })
    },
    salvarCamposAdicionais() {
      this.loadingCampos = true
      let listaSalvarCampos = []
      this.listaGrupos.forEach((grupo) => {
        grupo.procedimentos.forEach((procedimento) => {
          procedimento.campo_adicional.forEach((campo) => {
            listaSalvarCampos.push({
              atendimento: this.$route.params.id,
              campoAdicional: campo.id,
              valor: campo.valor ? campo.valor : null
            })
          })
        })
      })
      this.$store
        .dispatch(
          'atendimentoProfissionalModule/editCamposAdicionais',
          listaSalvarCampos
        )
        .then((response) => {
          if (
            response.response &&
            response.response.data &&
            response.response.data.error
          ) {
            this.MensagemError('Editar', 'Falha ao editar!')
            this.loadingCampos = false
            return
          }
          this.showMessageSuccess('Editar', 'Editado com sucesso!')
          this.cancelar()
        })
    }
  },
  async mounted() {
    this.aplicarEstiloDisabilitadoEmCampoData()
    const dateName = document.getElementsByName('fieldDateData')
    if (dateName.length > 0) {
      dateName.forEach((element) => {
        if (element.nextElementSibling) {
          element.nextElementSibling.style.backgroundColor = ''
          if (this.disableForm) {
            element.nextElementSibling.style.backgroundColor = '#efefef'
          }
        }
      })
    }
    await this.loadComboSituacao()
    await this.loadComboDoencasCid()
    this.$store
      .dispatch(
        'atendimentoProfissionalModule/getAtendimentoById',
        this.$route.params.id
      )
      .then(() => {
        this.form = JSON.parse(JSON.stringify(this.atendimento))
      })
    this.getGrupos()
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
.teeth {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 80px solid lightblue;
}
</style>
